
import { defineComponent } from 'vue'
import Topbar from '@/components/Topbar/Topbar.vue'
import SideNav from '@/components/SideNav/SideNav.vue'
// import '@progress/kendo-ui/js/kendo.dialog'
// import { Dialog } from '@progress/kendo-vue-dialogs'
// import useToggle from '@/composable/useToggle'

export default defineComponent({
  components: {
    Topbar,
    SideNav
  },
  setup() {
    return {}
  }
})
